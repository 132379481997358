/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import screenfull from 'screenfull';

import { useOnKeyPress } from '@helpers/hooks';
import useMouseMove from '@helpers/hooks/useMouseMove';
import useTouch from '@helpers/hooks/useTouch';
import checkDevice from '@helpers/checkDevice';
import useWindowSize from '@helpers/hooks/useWindowSize';
import isWebview from '@helpers/isWebview';
import CookiesService, { FEEDBACK_VIEWER, SEND_VIEWER_FEEDBACK } from '@app/services/CookiesService';
import TtsService from '@app/services/TtsV5Service';
import isSafari from '@helpers/isSafari';
import getSlideTxt from '@app/helpers/getSlideTxt';
import checkActiveOrder from '@helpers/checkActiveOrder';
import iOSversion from '@helpers/iOSversion';

import Icon from '@app/components/NEW_ui/Icon';
import Button from '@app/components/NEW_ui/Button';
import PaymentPopup from '@app/components/NEW_ui/PaymentPopup';
import RoundButton from '@app/components/NEW_ui/RoundButton';
import SlideCounter from '@app/components/NEW_ui/SlideCounter';
import Feedback from '@app/components/NEW_ui/Feedback';
import FullscreenTooltip from '@app/components/NEW_ui/FullscreenTooltip';
import SertificatePopup from '@app/components/NEW_ui/SertificatePopup';
import AudioControlBtn from '@app/components/NEW_ui/AudioControlBtn';

import backgroundMusic from '@app/assets/audio/backgroun-music.wav';
import { eraseData, getPresentation, getPresentationCharge, getTopic, makePayment, fetchFreeTopic, fetchQuiz } from './redux/actions';
import useViewer from './hooks/useViewer';

import Slide from './components/Slide';
import InformAlert from './components/InformAlert';

import './style.scss';

const dictors = {
  JULIA_RU: 'Ost_24000',
  VLADIMIR_RU: 'Bys_24000',
  CAROL_EN: 'Kin_24000',
  KELLY_EN: 'Kin_24000',
};

const isEnglishLanguage = presentation => presentation
  && presentation.meta
  && presentation.meta.language === 'English';

const Viewer = ({
  getTopic,
  getPresentation,
  getPresentationCharge,
  chargeData,
  makePayment,
  order,
  eraseData,
  presentation,
  history,
  showCertificatePopup,
  freeTopic,
  fetchFreeTopic,
  fetchQuiz,
}) => {
  const { t } = useTranslation();
  const exitBtn = useRef(null);
  const fullscreenBtn = useRef(null);

  // eslint-disable-next-line no-unused-vars
  const [topic, answers, pastyllaId, slides, slidesTotal, chargeOptions, dir] = useViewer(
    getTopic,
    getPresentation,
    getPresentationCharge,
    eraseData,
    presentation,
    chargeData,
  );

  const [tts, setTts] = useState(false);
  const [ttsDataLoaded, setTtsDataLoaded] = useState(false);
  const [english, setEnglish] = useState(true);
  const [dictor, setDictor] = useState(undefined);
  const [ttsActive, setTtsActive] = useState(false);
  const [audioPlay, setAudioPlay] = useState(false);
  const [backSound, setBackSound] = useState(null);

  const [slide, setSlide] = useState(1);

  // hack for english desktop
  const isBought = freeTopic || (!english && checkActiveOrder(order));
  // eslint-disable-next-line no-nested-ternary
  const ttsLastSlide = isBought
    ? slide !== slidesTotal
    : slidesTotal < 4
      ? slide !== slidesTotal
      : slide < 4;
  const [isFullscreen, changeFullscreen] = useState(screenfull.isFullscreen);
  const [showPaymentPopup, handleShowPaymentPopup] = useState(false);

  useEffect(() => {
    fetchQuiz();
  }, [fetchQuiz]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    topic && fetchFreeTopic(topic);
  }, [fetchFreeTopic, topic]);

  useEffect(() => {
    if (!presentation) return;
    const eng = isEnglishLanguage(presentation);
    setEnglish(eng);
    setDictor(!eng ? dictors.JULIA_RU : dictors.CAROL_EN);
  }, [presentation]);

  useEffect(() => {
    handleShowPaymentPopup(slide >= 4 && !isBought && !english);
  }, [slide, isBought, english]);

  // synthesize dictor speech
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Init tts');
    const instanceTts = new TtsService({
      voice: dictor,
    });
    instanceTts.onAudioPaused = () => setAudioPlay(false);
    instanceTts.onAudioPlaying = () => setAudioPlay(true);
    instanceTts.onFinishLoading = (loaded) => {
      setTtsDataLoaded(loaded);
      if (loaded && instanceTts) {
        // eslint-disable-next-line no-console
        console.log('onFinishLoading');
        instanceTts.audiosEnded = () => setTimeout(
          () => setSlide((slide) => {
            if (slide === slidesTotal - 1) {
              if (backSound) backSound.pause();
              setTtsActive(false);
            }
            return slide + 1;
          }),
          100,
        );
      }
    };

    setTts(instanceTts);

    if (presentation && ttsActive && ttsLastSlide) {
      if (presentation && ttsActive && ttsLastSlide) {
        const slideContains = presentation.topic.structure[slide - 1].contains;
        const { content } = presentation;

        if (dir) {
          const { _type: audioPrefix } = presentation.topic.structure[slide - 1];
          const lang = dictors.JULIA_RU === dictor || dictors.VLADIMIR_RU === dictor ? 'ru' : 'en';
          const gender = presentation.topic.gender.find(x => x);
          instanceTts.initAudio({
            dir,
            audioPrefix,
            content,
            lang,
            gender,
          });
          return;
        }

        const slideText = getSlideTxt(slideContains, content, presentation.topic);
        // eslint-disable-next-line promise/catch-or-return
        Promise.all([instanceTts.synthesize(slideText)]);
      }
    }

    return () => {
      instanceTts.audiosEnded = null;
      instanceTts.clearData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide, dictor, presentation, ttsActive, ttsLastSlide, slidesTotal, topic]);

  // set background sound
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Change backgroundSound state');
    const backgroundSound = new Audio(backgroundMusic);
    backgroundSound.loop = true;
    setBackSound(backgroundSound);
    if (ttsActive) {
      backgroundSound.play()
      // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    } else {
      backgroundSound.pause();
    }
    return () => backgroundSound.pause();
  }, [ttsActive]);

  const iOSv = iOSversion(window);
  const fullscreenSupproted = isSafari(window) && isWebview(window) && iOSv && iOSv[0] >= 13;
  const [showFullscreenTooltip, handleShowTooltip] = useState(fullscreenSupproted);

  const isMobileDevice = checkDevice(window);
  const mouseMove = useMouseMove(5000);
  const mobileTouch = useTouch(5000, [exitBtn, fullscreenBtn]);
  const move = isMobileDevice ? mobileTouch : mouseMove;
  // eslint-disable-next-line no-nested-ternary
  const showTtsActions = isMobileDevice
    ? iOSv
      ? true
      : mobileTouch
    : true;

  const [feedbackStatus, setFeedbackStatus] = useState('inactive');
  const supportFullscreenMode = screenfull.isEnabled && isMobileDevice;

  const monthAmount = chargeOptions?.monthAmount ?? 0;
  const yearAmount = chargeOptions?.yearAmount ?? 0;
  const yearDiscount = chargeOptions?.yearDiscount ?? 0;
  const monthDiscount = chargeOptions?.monthDiscount ?? 0;

  const ttsInProgress = ttsActive && !ttsDataLoaded;

  // watch clide changing
  useEffect(() => {
    const sendFeedback = CookiesService.getCookie(SEND_VIEWER_FEEDBACK);

    if (slide === slidesTotal && !sendFeedback) {
      setTimeout(() => {
        setFeedbackStatus('feedback');
      }, 750);
    }
  }, [slide, slidesTotal]);

  const incrementSlide = (slide) => {
    if (ttsInProgress) return slide;
    if (
      !isBought && (
        (!english && slide >= 4)
        || showPaymentPopup
        || (ttsInProgress && ttsLastSlide)
      )
    ) {
      return slide;
    }
    setTtsActive(false);
    setTtsDataLoaded(false);
    return slide + 1 > slidesTotal ? slidesTotal : slide + 1;
  };

  const decrementSlide = (slide) => {
    if (ttsInProgress) return slide;
    if (
      !isBought && (slide === 1
        || showPaymentPopup
        || (ttsInProgress && ttsLastSlide)
      )
    ) {
      return slide;
    }
    setTtsActive(false);
    setTtsDataLoaded(false);
    return slide === 1 ? 1 : slide - 1;
  };

  const handleFullScreen = () => {
    if (supportFullscreenMode) {
      screenfull
        .toggle()
        .then(() => changeFullscreen(screenfull.isFullscreen))
        // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    }
  };

  const handleTtsActive = () => setTtsActive((prevActive) => {
    if (prevActive) {
      tts.clearData();
    }
    return !prevActive;
  });

  const handlePlayPause = () => {
    if (audioPlay) {
      tts.pauseAudio();
      backSound.pause();
    } else {
      tts.playAudio();
      backSound.play()
      // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    }
  };

  const handleChangeDictor = (dictor) => {
    if (!ttsInProgress) {
      setDictor(dictor);
    }
  };

  const swipeableConfig = {
    delta: 50,
    trackTouch: true,
  };
  const swipeable = useSwipeable({
    onSwipedLeft: () => setSlide(slide => incrementSlide(slide)),
    onSwipedRight: () => setSlide(slide => decrementSlide(slide)),
    ...swipeableConfig,
  });

  const onCloseViewer = () => {
    tts.clearData();
    const shownFeedbackViewer = CookiesService.getCookie(FEEDBACK_VIEWER);

    if (!shownFeedbackViewer) {
      return setFeedbackStatus('feedback');
    }
    return history.push('/my-presentations');
  };

  const { width, height } = useWindowSize();
  const isMobileScreen = width <= 820;
  const slideListWidth = width * (slides && slides.length);

  const paymentHandler = period => makePayment(period);

  useOnKeyPress(['Escape'], onCloseViewer);
  useOnKeyPress(
    ['ArrowLeft'],
    () => setSlide(slide => decrementSlide(slide)),
    [
      slidesTotal,
      showPaymentPopup,
      isBought,
      ttsActive,
      ttsLastSlide,
      ttsDataLoaded,
    ],
  );
  useOnKeyPress(
    ['ArrowRight'],
    () => setSlide(slide => incrementSlide(slide)),
    [
      slidesTotal,
      showPaymentPopup,
      isBought,
      ttsActive,
      ttsLastSlide,
      ttsDataLoaded,
    ],
  );

  return presentation && chargeOptions ? (
    <div className="pst-viewer">
      <div className="pst-viewerSidebar">
        <div
          className={`pst-viewerSidebar__exit pst-viewerSidebar__exit--${
            move ? 'show' : 'hidden'
          }`}
          ref={exitBtn}
        >
          <RoundButton
            name="Close"
            size={isMobileScreen ? 'sm' : 'md'}
            onClick={onCloseViewer}
          />
        </div>

        <div
          className={`pst-viewerSidebar__slideCounter pst-viewerSidebar__slideCounter--${
            move ? 'show' : 'hidden'
          }`}
        >
          <SlideCounter count={slide} max={slidesTotal}/>
        </div>

      </div>

      {showPaymentPopup && (
        <PaymentPopup
          priceMonth={monthAmount}
          discountMonth={monthDiscount}
          priceYear={yearAmount}
          discountYear={yearDiscount}
          isBought={isBought}
          storyId={pastyllaId}
          closePopup={onCloseViewer}
          buyAction={paymentHandler}
        />
      )}

      <ul
        className="pst-slideList pst-slideList"
        style={{
          width: `${slideListWidth}px`,
          left: `${-(slide - 1) * width}px`,
        }}
        {...swipeable}
      >
        {slides.map(({ html, _id }) => (
          <li className="pst-slideList__slide" key={_id}>
            <Slide width={width} height={height}>{html}</Slide>
          </li>
        ))}
      </ul>

      <div className="pst-viewer__fullScreenBtn" ref={fullscreenBtn}>
        {supportFullscreenMode && (
          <Button template="withoutBackground" onClick={handleFullScreen}>
            <Icon name={isFullscreen ? 'FullscreenClose' : 'FullscreenOpen'}/>
          </Button>
        )}
      </div>

      <div
        className={`pst-viewer__dictors pst-viewer__dictors--${
          ttsActive ? 'bottomUp' : 'bottomDown'
        } pst-viewer__dictors--${ttsLastSlide && showTtsActions ? 'show' : 'hide'}`}
      >
        {!english && <>
          <AudioControlBtn
            size="small"
            iconName="DictorWoman"
            isActive={dictor === dictors.JULIA_RU}
            disabled={dictor === dictors.JULIA_RU || ttsInProgress}
            onClick={() => handleChangeDictor(dictors.JULIA_RU)}
          />

          <AudioControlBtn
            size="small"
            iconName="DictorMan"
            isActive={dictor === dictors.VLADIMIR_RU}
            disabled={dictor === dictors.VLADIMIR_RU || ttsInProgress}
            onClick={() => handleChangeDictor(dictors.VLADIMIR_RU)}
          />
        </>}
        {english && <>
          <AudioControlBtn
            size="small"
            iconName="DictorWoman"
            isActive={dictor === dictors.CAROL_EN}
            disabled={dictor === dictors.CAROL_EN || ttsInProgress}
            onClick={() => handleChangeDictor(dictors.CAROL_EN)}
          />
        </>}
      </div>

      <div
        className={`pst-viewer__pauseAudio pst-viewer__pauseAudio--${
          ttsActive ? 'show' : 'hide'
        } pst-viewer__pauseAudio--${ttsLastSlide && showTtsActions ? 'show' : 'hide'}`}
      >
        <AudioControlBtn
          size="small"
          iconName={audioPlay ? 'PauseIcon' : 'PlayAudio'}
          isActive={audioPlay}
          disabled={!ttsDataLoaded}
          onClick={handlePlayPause}
        />
      </div>

      <div
        className={`pst-viewer__audioPlay pst-viewer__audioPlay--${
          tts && presentation && ttsLastSlide && showTtsActions ? 'show' : 'hide'}`}
      >
        <AudioControlBtn
          size="big"
          iconName="Audio"
          loading={ttsInProgress}
          isActive={ttsActive}
          disabled={ttsDataLoaded}
          onClick={handleTtsActive}
        />
      </div>

      {isMobileDevice && (
        <div className="pst-viewer__informAlert">
          <InformAlert icon="RotateDevice" message={t('viewer.informAlert')}/>
        </div>
      )}

      <Feedback mod="viewer" externalStatus={feedbackStatus}/>

      {showFullscreenTooltip && (
        <div className="pst-viewer__fullscreenTooltip">
          <FullscreenTooltip handleClose={() => handleShowTooltip(false)}/>
        </div>
      )}

      {showCertificatePopup && <SertificatePopup/>}
    </div>
  ) : null;
};

const mapStateToProps = ({ viewer, subscriptions, sertificate }) => ({
  presentation: viewer.presentation,
  chargeData: viewer.chargeData,
  order: subscriptions.order,
  showCertificatePopup: sertificate.showCertificatePopup,
  freeTopic: subscriptions.freeTopic,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getTopic,
    getPresentation,
    getPresentationCharge,
    makePayment,
    eraseData,
    fetchFreeTopic,
    fetchQuiz,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Viewer));
