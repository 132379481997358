import http, { apiV4 } from '@app/services/HttpService';

export const fetchAudios = ({ dir, lang, gender }) => http.get(`tts/audios/${dir}/${lang}/${gender}`).then(({ data }) => data);

export const fetchAudio = ({ dir, lang, gender, fileName }) => http.get(`tts/audios/${dir}/${lang}/${gender}/${fileName}`, {
  responseType: 'arraybuffer',
}).then(({ data }) => new Blob([data], {
  type: 'audio/x-wav',
}));

export const fetchVariable = text => apiV4.post('tts/synthesize', {
  text,
}, {
  responseType: 'arraybuffer',
}).then(({ data }) => new Blob([data], {
  type: 'audio/x-wav',
}));
